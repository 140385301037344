const LangEN = {
  id: "en",
  navmenu: {
    home: "Home",
    allRepositories: "All Projects",
    about: "About",
    activities: "Activities",
    publications: "Publications",
    repositories: "Repositories",
    contactMe: "Contact Me",
  },
  skills: {
    neuropsychology: "Neuropsychology",
    psychometrics: "Psychometrics",
    psychopharmacology: "Psychopharmacology",
    intellectualAssessment: "Intellectual Assessment",
    emotionalIntellectualAssessment: "Emotional Intellectual Assessment",
    researchDesign: "Research Design",
    python: "Python",
    statisticalModeling: "Statistical Modeling",
    langR: "R",
  },
  messages: {
    langToggle: "Toggle language, currently",
    readonline: "Read online",
    viewon: "View on",
  },
  contact: {
    name: "Name",
    namePrefill: "Your name",
    nameCheck: "Name must be at least one character.",
    email: "Email",
    emailPrefill: "someone@youremailprovider.com",
    emailCheck: "Please enter a valid email.",
    message: "Message",
    messagePrefill: "Your message",
    messageCheck: "Please provide a valid message.",
    sendBtn: "Send",
    sendSuccess: "Success! I will contact you soon.",
    sendError: "Something is wrong that is not right 😥😣 You can send an email directly to contact@dr.victorvs.com."
  },
  misc: {
    aboutMe: "About Me",
    desc: "Neuropsychologist and Psychometrician",
    portfolio: "Portfolio",
    webpage: "Dr. Victor Vasconcelos | Webpage",
    moreInfo:
      "Licensed neuropsychologist and a University of Brasilia alumnus. Doctorate and Master's degree were both sought under the guidance of Prof. Dr. Cristiane Faiad in the Psychological Assessment and Instrumentation concentration of the Post-Graduate Program of Social, Work and Organizational Psychology—also at the University of Brasília. Specialized in the use of programming resources in Education and Psychometrics (Statistical Modeling, Psychological and Educational Assessment). Experience in statistical analysis in biomedical sciences, neurosciences and psychology. Worked for two years in psychopharmacology labs, using either oral or intrahippocampal perfusion methodology. Currently working as a psychometrician at the National Institute of Study and Research in Education.",
  },
  scientific: {
    'pbjs2015': {
      star: true,
      name: "Effects of Oral Vitamin C Supplementation on Anxiety in Students: A Double-Blind, Randomized, Placebo-Controlled Trial",
      publication: "Pakistan Journal of Biological Sciences",
      publisher: "Asian Network for Scientific Information",
      lang: "en",
      abstract: "",
    },
    'eip2018': {
      star: false,
      name: "Psychometric Properties of the ISSL in the Context of Public Security",
      publication: "Estudos Interdisciplinares em Psicologia [Interdisciplinary Studies in Psychology]",
      publisher: "Universidade Estadual de Londrina [Londrina State University]",
      lang: "pt",
      abstract: "",
    },
    'eae2018': {
      star: false,
      name: "Evidence of Content Validity in the ENADE Psychology Test",
      publication: "Estudos em Avaliação Eduacacional [Studies in Educational Assessment]",
      publisher: "Fundação Carlos Chagas [Carlos Chagas Foundation]",
      lang: "pt",
      abstract: "",
    },
    'pn2019': {
      star: true,
      name: "Effects of Ascorbic Acid Combined with Environmental Enrichment on Anxiety and Memory",
      publication: "Psychology & Neuroscience",
      publisher: "American Psychological Association",
      lang: "en",
      abstract: "",
    },
    'eappe2022': {
      star: false,
      name: "The Question of Validity in Brazilian Educational Assessment [A Questão da Validade na Avaliação Educacional]",
      publication: "Ensaio",
      publisher: "Cesgranrio",
      lang: "pt",
      abstract: "",
    },
    'rr2024': {
      star: true,
      name: "The Situational Tests of Emotional Intelligence as a Computer-Adaptive Test",
      publisher: "Instituto Federal do Norte de Minas Gerais [North Minas Gerais Federal Institute]",
      publication: "Recital",
      lang: "en",
      abstract: "",
    },
  },
};

export default LangEN;
